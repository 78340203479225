import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React from "react";
import { LoincCodingCode } from "src/constants/fhir";
import {
  A1cIcon,
  IconHeartPulse,
  IconMeterGradient,
  IconWeightScaleGradient,
  IconWorkInProgress
} from "src/constants/icons";
import DataHubCubit from "./DataHubCubit";

//#region Styled components
const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  top: 14em;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5em 5em;
  z-index: 1;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 31%
  );
  backdrop-filter: blur(6px);

  .loading-progress {
    opacity: 1;
  }
`;

const Title = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.125rem;
  font-weight: 500;
`;

const Description = styled.p`
  margin: 0 0 1.75rem;
  color: var(--color-charcoal-80);
`;
//#endregion

const ChartNotAvailableOverlay: FC<{
  before?: ReactNode;
  children?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  after?: ReactNode;
}> = (props) => {
  const [{ activeLoincCode }] = useBloc(DataHubCubit);

  const getIcon = (activeLoincCode: LoincCodingCode) => {
    switch (activeLoincCode) {
      case LoincCodingCode.hba1c:
        return <A1cIcon />;
      case LoincCodingCode.weight:
        return <IconWeightScaleGradient />;
      case LoincCodingCode.waistCircumference:
        return <IconMeterGradient />;
      case LoincCodingCode.stepsInDay:
        return <IconHeartPulse />;
      default:
        return <IconWorkInProgress />;
    }
  };

  if (!activeLoincCode) {
    return null;
  }

  const icon = getIcon(activeLoincCode);

  return (
    <Wrapper className="empty-state">
      {props.children ?? (
        <>
          {props.before ?? icon}
          <Title>{props.title}</Title>
          <Description>{props.description}</Description>
          {props.after}
        </>
      )}
    </Wrapper>
  );
};

export default ChartNotAvailableOverlay;
