import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import StatusBarBloc from "src/hybrid/components/StatusBarBloc";
import { useBloc } from "src/state/state";
import BlockingLoadingOverlayController, {
  BlockingLoadingOverlayBloc
} from "../BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { useLocation } from "react-router-dom";

const StyledStatusBar = styled.div`
  label: StyledStatusBar;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: var(--ion-safe-area-top, 0px);
  pointer-events: none;
  z-index: 999999;
  backdrop-filter: blur(0.5rem);
  transition: background 0.3s ease-in-out;
  transition-property: background, backdrop-filter, opacity;

  &[data-render-portal="true"] {
    pointer-events: all;
  }

  @media (min-width: 730px) {
    top: calc(62px + var(--ion-safe-area-top, 0px));
    padding-top: 0;
  }

  &[data-popup-open="true"] {
    background: transparent !important;
    backdrop-filter: blur(0) !important;
    opacity: 0 !important;
  }

  &[data-hide-for-location="true"] {
    display: none;
  }
`;

const Center = styled.div`
  opacity: 0;
  padding: 0 2rem;

  [data-show="true"] & {
    opacity: 1;
    padding: 1.3rem 2rem;
  }

  [data-render-portal="true"] & {
    padding: 1.3rem 0 1.3rem 1rem;
    border-bottom: 1px solid var(--secondary-dark-cream, #f2efe7);
  }
`;

const BackButtonPortalTarget = styled.div`
  position: relative;
  width: min(100vw, ${APP_CONTENT_WIDTH}px);
  margin: 0 auto;
  height: 0;
  overflow: visible;
  padding: 0;
  box-sizing: border-box;
`;

const TopBarTitle = styled.p`
  body & {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`;

const AppStatusBar: FC = () => {
  const location = useLocation();
  const [{ status }] = useBloc(BlockingLoadingOverlayBloc, {
    create: () => BlockingLoadingOverlayController
  });

  const [{ navBarColor, title, renderPortal, popupOpen }] =
    useBloc(StatusBarBloc);
  const statusBarRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    let prevHeight = 0;
    const updateStatusBarHeight = (h?: number) => {
      if (statusBarRef.current || typeof h === "number") {
        const height = h ?? statusBarRef.current?.offsetHeight;
        const changed = height !== prevHeight;
        if (!changed || !height) return;

        prevHeight = height;
        document.documentElement.style.setProperty(
          "--status-bar-top",
          `${height}px`
        );
      }
    };

    if (statusBarRef.current) {
      updateStatusBarHeight();
      const to = setInterval(updateStatusBarHeight, 1000);

      return () => {
        clearInterval(to);
        updateStatusBarHeight(0);
      };
    }
  }, [statusBarRef]);

  const hideForLocation = useMemo(() => {
    const hide = location.pathname === "/app/lab-results";
    return hide;
  }, [location.pathname, location.search]);

  const hideForLoadingOverlay =
    status === "loading" || status === "error" || status === "success";

  const showStatusBar =
    (renderPortal || Boolean(title)) && !hideForLoadingOverlay;

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={navBarColor} />
      </Helmet>
      <StyledStatusBar
        data-render-portal={String(renderPortal)}
        style={{ background: `${navBarColor}aa` }}
        ref={statusBarRef}
        data-popup-open={String(popupOpen)}
        data-show={showStatusBar}
        data-hide-for-location={String(hideForLocation)}
      >
        <Center data-render-portal={String(renderPortal)}>
          <BackButtonPortalTarget id={"backButtonPortalTarget"} />
          <TopBarTitle>{title}</TopBarTitle>
          <div id="portal"></div>
        </Center>
      </StyledStatusBar>
    </>
  );
};

export default AppStatusBar;
