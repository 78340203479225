import { SubscriptionDetailsResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import {
  BLOOD_SPOT_SKU,
  CDCDPP_PROGRAM_ITEM_SKU,
  PHLEBOTOMY_SKU
} from "src/constants/misc";
import DevicesCubit from "src/state/DevicesCubit/DevicesCubit";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import TaskManagementBloc from "src/state/TaskManagementBloc/TaskManagementBloc";
import { useBloc } from "src/state/state";
import { TranslationKey } from "src/types/translationKey";
import LabTestsImage from "src/ui/assets/in_lab_blood_draw.jpg";
import HomePhlebotomyImage from "src/ui/assets/phlebotomy_square_new.jpg";
import ShipmentsList from "src/ui/components/ShipmentsList/ShipmentsList";
import { AccessToCareSection } from "./AccessToCareSection";
import { LifestyleSection } from "./LifestyleSection";
import { MedicationSection } from "./MedicationSection";
import { SuppliesDevicesSection } from "./SuppliesDevicesSection";

export const Wrapper = styled.div`
  padding: 0 var(--space-md);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: var(--space-md);
`;

export interface LabInfo {
  slug: string;
  imageSrc: string;
  imageAlt: string;
  title: TranslationKey;
  description: TranslationKey;
}

export const FullSubscriptionPlanOverviewContent: FC = () => {
  const [
    { allSubscriptions = [] },
    {
      getParsedMeds,
      getParsedSupplies,
      checkHasLabs,
      checkHasPlan,
      checkHasTestStrips,
      getFullSubscription,
      removeTestStripsDraftSubscriptionCoupons
    }
  ] = useBloc(SubscriptionCubit);

  const [{ devices }, { getUserDevices }] = useBloc(DevicesCubit, {
    create: () => new DevicesCubit()
  });

  const subscription = useMemo(() => {
    const active = getFullSubscription([
      SubscriptionDetailsResponse.status.ACTIVE
    ]);
    const inReview = getFullSubscription([
      SubscriptionDetailsResponse.status.IN_REVIEW
    ]);
    const paused = getFullSubscription([
      SubscriptionDetailsResponse.status.PAUSED
    ]);
    const draft = getFullSubscription([
      SubscriptionDetailsResponse.status.DRAFT
    ]);

    return active ?? inReview ?? paused ?? draft;
  }, [allSubscriptions]);

  const parsedMeds = getParsedMeds(subscription?.id);
  const showMeds = parsedMeds.length > 0;

  const parsedSupplies = getParsedSupplies(subscription?.id);

  const hasLabs = checkHasLabs(subscription?.id);
  const getLabInformation = (sku: string): LabInfo | undefined => {
    switch (sku) {
      case PHLEBOTOMY_SKU: // At home lab
        return {
          slug: `${PHLEBOTOMY_SKU}_TREATMENT_PLAN_DETAILS`,
          imageSrc: HomePhlebotomyImage,
          imageAlt: "image.alt.professionalTakingBloodSampleAtHome",
          title: "suggestion_item_title_AT_HOME_PHLEBOTOMY_extended",
          description: "suggestion_item_description"
        };

      case BLOOD_SPOT_SKU: //Regular lab
        return {
          slug: `${BLOOD_SPOT_SKU}_TREATMENT_PLAN_DETAILS`,
          imageSrc: LabTestsImage,
          imageAlt: "image.alt.professionalTakingBloodSampleInLab",
          title: "suggestion_item_title_DRY_BLOOD_SPOT_LAB_TEST_extended",
          description: "suggestion_item_description"
        };
    }
  };
  const labInfo = hasLabs && getLabInformation(hasLabs.sku);

  const hasPlan = checkHasPlan(subscription?.id);
  const hasTestStrips = checkHasTestStrips();

  const showAccessToCareTeam = hasPlan;

  const showTestStrips = Boolean(hasTestStrips);
  const testStripsQuantity = hasTestStrips?.quantity;
  const testStripsMeasurementFrequency = (testStripsQuantity ?? 100) / 100;

  const isCDCDPPSubscription = subscription?.purchaseItems.some(
    (item) => item.sku === CDCDPP_PROGRAM_ITEM_SKU
  );

  // Show RxDiet nutrition program for everyone except DPP
  const showNutritionProgram = !isCDCDPPSubscription;

  const [taskState, { loadProgramTasks, getProgramTasks }] =
    useBloc(TaskManagementBloc);

  const showLifestyleCoaching = useMemo(
    () => getProgramTasks(KnownProgram.LIFEBALANCE).length > 0,
    [taskState.tasks]
  );

  useEffect(() => {
    void getUserDevices();
    void loadProgramTasks(KnownProgram.LIFEBALANCE);
    void removeTestStripsDraftSubscriptionCoupons();
  }, []);

  const storedDevices = devices.filter(
    (device) => device.prescriptionId === null
  );
  const showSuppliesAndDevices =
    parsedSupplies.length > 0 || storedDevices.length > 0;

  return (
    <Container>
      <ShipmentsList />

      {/* Prescription medications */}
      {showMeds && (
        <MedicationSection
          parsedMeds={parsedMeds}
          subscription={subscription}
        />
      )}

      {/* Devices, supplies and test strips */}
      {(showSuppliesAndDevices || showTestStrips) && (
        <SuppliesDevicesSection
          showTestStrips={showTestStrips}
          parsedSupplies={parsedSupplies}
          storedDevices={storedDevices}
          testStripsMeasurementFrequency={testStripsMeasurementFrequency}
          testStripsQuantity={testStripsQuantity}
        />
      )}

      {(showNutritionProgram || showLifestyleCoaching) && (
        <LifestyleSection
          showNutritionProgram={showNutritionProgram}
          showLifestyleCoaching={showLifestyleCoaching}
        />
      )}

      {/* Access to Care Team */}
      {(showAccessToCareTeam || labInfo) && (
        <AccessToCareSection
          showAccessToCareTeam={showAccessToCareTeam}
          labInfo={labInfo}
        />
      )}
    </Container>
  );
};
