import type { FC } from "react";
import React, { useEffect } from "react";
import ProgramBloc, { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import ProgramPreviewCard from "src/ui/components/ProgramPreviewCard/ProgramPreviewCard";

const ProgramOverview: FC = () => {
  const [state, programBloc] = useBloc(ProgramBloc);

  useEffect(() => {
    void programBloc.updatePrograms();
  }, []);

  return (
    <>
      {state.programs.has(KnownProgram.CDC_DPP) ? (
        <ProgramPreviewCard program={KnownProgram.CDC_DPP} />
      ) : (
        <ProgramPreviewCard hideActions hideLink />
      )}
    </>
  );
};

export default ProgramOverview;
