import { NineSpacer } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { Locale } from "src/constants/language";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";
import UserPreferencesCubit, {
  UserPreferenceKeys
} from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import Translate from "src/ui/components/Translate/Translate";
import { Divider } from "src/ui/styled/X";
import RadioGroup from "molecule/radio/RadioGroup";
import Radio from "molecule/radio/Radio";

const RadioGroupWrapper = styled.div`
  background-color: var(--color-white);
  box-shadow: var(--light-shadow);
  border: 2px solid var(--color-white);
  border-radius: var(--border-radius);
`;

const RadioWrapper = styled.div`
   padding: 2rem;
`;

const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LanguageSettings: FC = () => {
  const language = getSupportedUserLocale();
  const [, { updateUserPreferences }] = useBloc(UserPreferencesCubit);
  const [preferredLanguage, setPreferredLanguage] = React.useState(language);
  const title = translate("language");

  const handleChange = (value: string) => {
    const locale = value as Locale;
    sessionStorage.removeItem("9am.initialLanguage");
    void updateUserPreferences({
      [UserPreferenceKeys.language]: locale
    });
    setPreferredLanguage(locale);
  };

  return (
    <>
      <OverlayBackButton show fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={title}></AppPageTitle>
      <NineSpacer s="xl" />

      <RadioGroupWrapper>
        <RadioGroup value={preferredLanguage} onChange={handleChange} defaultValue="dogs">
          <RadioWrapper>
            <Radio large value={Locale.en}>
              <div style={{marginLeft: "1rem"}}>
                <strong> English (United States)</strong>
                <p className="m0 color-c-80 as-little">
                  <Translate msg="language.english"/>{" "}
                </p>
              </div>
            </Radio>
          </RadioWrapper>

          <DividerWrapper>
            <Divider className="m0 p" style={{width: "85%"}}/>
          </DividerWrapper>
          <RadioWrapper>
            <Radio large value={Locale.es}>
              <div style={{marginLeft: "1rem"}}>
                <strong>Español</strong>
                <p className="m0 color-c-80 as-little">
                  <Translate msg="language.spanish"/>{" "}
                </p>
              </div>
            </Radio>
          </RadioWrapper>
        </RadioGroup>
      </RadioGroupWrapper>
    </>
);
};

export default LanguageSettings;
