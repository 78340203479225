import { AriaRadioGroupProps, useRadioGroup } from "react-aria";
import React from "react";
import { z } from "zod";
import { useRadioGroupState } from "react-stately";
import {
  RadioFieldError,
  RadioGroupWrapper
} from "atom/radio/radioComponents";
import { RadioContext } from "atom/radio/RadioContext";

export interface RadioGroupProps extends AriaRadioGroupProps {
  children: React.ReactNode;
  errorParser?: (props: RadioGroupProps) => string;
  error?: string | z.ZodIssue;
}

export function RadioGroup(props: RadioGroupProps) {
  const {
    children,
    label,
    description,
    errorParser = ({ error }) => {
      if (!error) return "";
      if (typeof error === "string") return error;
      return error.message;
    }
  } = props;

  const state = useRadioGroupState(props);

  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup(props, state);

  const errorString = errorParser(props);

  return (
    <RadioGroupWrapper {...radioGroupProps}>
      <span {...labelProps}>
        {label}
        {label && props.isRequired ? "*" : ""}
      </span>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
      {description && (
        <div {...descriptionProps} className="little1">
          {description}
        </div>
      )}
      {errorString && (
        <RadioFieldError {...errorMessageProps} className="little1">
          {errorString}
        </RadioFieldError>
      )}
    </RadioGroupWrapper>
  );
}

export default RadioGroup;
