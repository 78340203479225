import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import type {
  AppTaskDetails,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import MediaPlayer from "src/ui/components/MediaPlayer/MediaPlayer";

const Wrap = styled.div`
  max-width: 740px;
  margin: 0 auto;
  width: 100%;
  padding: 0 0.8rem;

  .media-player {
    border-radius: var(--border-radius, 0);
    box-shadow: var(--light-shadow);
  }
`;

const VideoTask: FC<{
  task: TaskResponseKnown;
  taskDetails: AppTaskDetails;
  onComplete?: () => void;
  onStart?: () => void;
}> = ({ taskDetails, onComplete, onStart }) => {
  return (
    <Wrap>
      <MediaPlayer
        videoId={taskDetails.videoId}
        videoProvider={taskDetails.videoProvider}
        options={{
          autoplay: true
        }}
        onVideoStart={onStart}
        onVideoEnd={onComplete}
        callVideoEndSecondsBeforeFinish={25}
        inline
      />
    </Wrap>
  );
};

export default VideoTask;
