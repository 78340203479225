import { useBloc } from "@blac/react";
import Chart from "molecule/chart/Chart";
import React, { FC } from "react";
import ChartEmptyState from "./ChartEmptyState";
import DataHubCubit from "./DataHubCubit";
import { LoincCodingCode } from "src/constants/fhir";
import ChartLoadingState from "./ChartLoadingState";
import ChartComingSoon from "./ChartComingSoon";
import { ChartColors } from "atom/chart/ChartBaseConfig";

const DataHubChart: FC = () => {
  const [
    { activeLoincCode, chartData, chartLabel },
    { tickFormatterAxisX, formatLabel, formatValue, availableCharts }
  ] = useBloc(DataHubCubit);
  const isAvailable = activeLoincCode
    ? availableCharts.includes(activeLoincCode)
    : false;

  const defaultXAxis: React.ComponentProps<typeof Chart>["xAxis"] = {
    type: "number",
    scale: "time",
    tickFormatter: (time: number) => tickFormatterAxisX(time),
    ticks: chartLabel?.ticks,
    domain: chartLabel?.domain,
    dataKey: "time"
  };

  // loading state for chart
  if (isAvailable && typeof chartData === "undefined") {
    return <ChartLoadingState />;
  }

  if (!isAvailable) {
    return <ChartComingSoon />;
  }

  if (!chartData || chartData.length === 0) {
    return <ChartEmptyState />;
  }

  return (
    <>
      {activeLoincCode === LoincCodingCode.waistCircumference && (
        <Chart
          //** Waist Circumference Specific */
          lines={[
            {
              color: ChartColors.Violet,
              dataKey: "value"
            }
          ]}
          xAxis={defaultXAxis}
          yAxis={{
            tickFormatter: (value) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              typeof value === "number" ? Math.round(value) : value,
            domain: [20, 60],
            padding: { bottom: 10 }
          }}
          //** Default props */
          type="line"
          chartData={chartData}
          tooltip={{
            formatLabel,
            formatValue
          }}
        />
      )}

      {activeLoincCode === LoincCodingCode.weight && (
        <Chart
          //** Weight Specific */
          lines={[
            {
              color: ChartColors.Pink,
              dataKey: "value"
            }
          ]}
          xAxis={defaultXAxis}
          yAxis={{
            tickFormatter: (value) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              typeof value === "number" ? Math.round(value) : value,
            domain: [140, 180],
            padding: { bottom: 10 }
          }}
          //** Default props */
          type="line"
          chartData={chartData}
          tooltip={{
            formatLabel,
            formatValue
          }}
        />
      )}

      {activeLoincCode === LoincCodingCode.hba1c && (
        <Chart
          //** A1c Specific */
          lines={[
            {
              color: ChartColors.Orange,
              dataKey: "value"
            }
          ]}
          xAxis={defaultXAxis}
          yAxis={{ ticks: [5.7, 6.5], domain: [4.8, 7] }}
          referenceLines={[
            { y: 5.7, stroke: ChartColors.Green },
            { y: 6.5, stroke: ChartColors.Orange }
          ]}
          //** Default props */
          type="line"
          chartData={chartData}
          tooltip={{
            formatLabel,
            formatValue
          }}
        />
      )}

      {activeLoincCode === LoincCodingCode.stepsInDay && (
        <Chart
          //** Steps Specific */
          bars={[
            {
              color: ChartColors.VioletLight,
              dataKey: "value"
            }
          ]}
          xAxis={defaultXAxis}
          yAxis={{
            ticks: [5000, 10000, 15000],
            domain: [0, 20000],
            tickFormatter: (value: number) => {
              return `${(value / 1000).toFixed(1).replace(/\.0$/, "")}K`;
            }
          }}
          //** Default props */
          type="bar"
          chartData={chartData}
          tooltip={{
            formatLabel,
            formatValue
          }}
        />
      )}
    </>
  );
};
export default DataHubChart;
