import type { FC } from "react";
import React, { useEffect } from "react";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import ObservationBundleBloc from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import { useBloc } from "src/state/state";
import DataTrendPreviewCard from "src/ui/components/DataTrendPreviewCard";
import LabResultGroupTable from "src/ui/components/LabResultGroupTable";
import DataHub from "../DataHub/DataHub";
import { useBloc as useBloc2 } from "@blac/react";
import { Pad } from "src/ui/styled/Pad";

const UserLabResults: FC = () => {
  const [{ observations }, { loadObservations }] = useBloc2(LabResultsCubit);
  const [{ observationBundles }, { loadObservationBundles }] = useBloc(
    ObservationBundleBloc
  );

  const observationBundlesLimited = observationBundles?.slice(0, 20);

  useEffect(() => {
    void loadObservations();
    void loadObservationBundles();
  }, []);

  return (
    <Pad>
      {/* Data Hub */}
      <DataHub />

      {/* List readings */}
      {/* <AjdinK> TODO: Remove this once DataHub is complete */}
      <DataTrendPreviewCard observations={observations} />

      <nine-spacer s="lg" />
      <nine-spacer s="xs" />

      {/* List lab results */}
      <LabResultGroupTable observationBundles={observationBundlesLimited} />
    </Pad>
  );
};

export default UserLabResults;
