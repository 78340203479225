import { AriaRadioProps, useRadio } from "react-aria";
import React from "react";
import { Label, StyledRadio } from "atom/radio/radioComponents";
import { RadioContext } from "atom/radio/RadioContext";

export function Radio(props: AriaRadioProps & { large?: boolean }) {
  const { children } = props;
  const state = React.useContext(RadioContext);
  const ref = React.useRef(null);
  const { inputProps } = useRadio(props, state, ref);

  return (
    <Label>
      <StyledRadio
        style={{
          ...(props.large ? { "--size-ratio": 1.9 } : {})
        } as  React.CSSProperties}
        {...inputProps}
        ref={ref} />
      {children}
    </Label>
  );
}

export default Radio;
