import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import type {
  AppTaskDetails,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";

const Wrap = styled.div`
  nine-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
`;

const QuestionnaireTask: FC<{
  task: TaskResponseKnown;
  taskDetails: AppTaskDetails;
  onComplete?: () => void;
  onClose?: () => void;
  onStart?: () => void;
}> = ({ taskDetails, onComplete, onStart, onClose }) => {
  const [progress, setProgress] = React.useState(0);
  const eventCounter = React.useRef({
    start: 0
  });

  const updateProgress = (step: number, total: number) => {
    setProgress((step / total) * 100);
  };

  useEffect(() => {
    if (taskDetails.questionnaireId && eventCounter.current.start === 0) {
      onStart?.();
      eventCounter.current.start++;
    }
  }, [taskDetails.questionnaireId]);

  if (!taskDetails.questionnaireId) return null;
  return (
    <Wrap>
      <nine-progress-bar progress={`${progress}%`}></nine-progress-bar>
      <CustomQuestionnaire
        autoSkip={false}
        resetScrollOnEachStep
        id={taskDetails.questionnaireId}
        useRouting
        onStep={updateProgress}
        onDataSent={(): void => {
          onComplete?.();
        }}
        onLastStepCompleted={onClose}
      />
    </Wrap>
  );
};

export default QuestionnaireTask;
