import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import translate from "src/lib/translate";
import type { Progress } from "./DataHubCubit";
import DataHubCubit from "./DataHubCubit";
import { renderTimeRangeContext } from "./DataTimeRangeButtons";
import Translate from "src/ui/components/Translate/Translate";

//#region Styled components
const Wrapper = styled.div`
  margin: 0 0 1rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin: 0 0 1.25rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 0.25rem;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 1.125rem;

    @media screen and (min-width: ${APP_BREAKPOINT}px) {
      font-size: 1.25rem;
    }
  }
`;

const TimeRange = styled.p`
  color: var(--color-charcoal);
`;

const Progress = styled.p`
  color: var(--color-charcoal-60);
`;
//#endregion

const DataStatus: FC = () => {
  const [{ activeTimeRange, activeProgress }] = useBloc(DataHubCubit);

  return (
    <Wrapper className="status">
      <FlexContainer>
        <TimeRange>
          {translate("timeRange.long", {
            context: renderTimeRangeContext(activeTimeRange?.domain)
          })}
        </TimeRange>

        {activeProgress?.difference !== undefined && (
          <Progress>
            {activeProgress.difference > 0 && activeProgress.unit !== "steps"
              ? "+"
              : ""}
            {activeProgress.difference.toFixed(
              activeProgress.unit === "steps" ? 0 : 1
            )}{" "}
            {activeProgress.unit}
            {activeProgress.unit === "steps" && (
              <Translate
                msg="chart.average"
                variables={{ context: "perDay" }}
              />
            )}
          </Progress>
        )}
      </FlexContainer>
    </Wrapper>
  );
};

export default DataStatus;
